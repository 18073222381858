import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import CustomTab from '../components/CustomTab';
import SubPageHeader from '../components/SubPageHeader';

export default function CheckInStovesDetailsPage({ data, pageContext }) {
  const details = data.stove;
  const breadcrumbs = [
    {
      title: 'Check-in',
      url: '/check-in',
    },
    {
      title: pageContext.familyName,
      url: `/check-in/${pageContext.familyUrl}`,
    },
  ];
  return (
    <Layout>
      <SubPageHeader title={pageContext.name} breadcrumbs={breadcrumbs} />
      <CustomTab
        gallery={details.check_in_gallery}
        video={details.check_in_video}
      />
    </Layout>
  );
}

export const query = graphql`
  query CheckInStoveQuery($url: String) {
    stove: sanityStove(url: { current: { eq: $url } }) {
      check_in_video
      id
      family {
        name
        url {
          current
        }
        id
      }
      check_in_gallery {
        description
        alt
        image {
          asset {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`;
